header
	.header-wrapper
		z-index: 100
		.header-flex
			.block-left
				.main-nav
					nav
						ul
							li
								&:hover
									a
										transition: all ease-in-out .3s
										font-weight: 700
										@apply text-primary-FFC20E
								&.active
									a
										font-weight: 700
										@apply text-primary-2D853D
					&.active
						visibility: visible
						opacity: 1
						left: 0
					@media screen and ( max-width: 640px )
						width: r(310px)
						min-height: r(812px)
						padding: r(16px) r(24px)
						top: 100%
						left: -100%
						margin-left: 0
						visibility: hidden
						box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12)
						@apply bg-primary-FFFFFF transition-all
						nav
							ul
								li
									margin-bottom: 32px
									a
										font-size: 16px
										font-weight: 700
									&:last-child
										a
											font-weight: 400

			.block-right
				.group
					button
						.avatar
							width: r(48px)
							height: r(48px)
							img
								width: 100%
								height: 100%
