.block-title
	font-size: r(24px)
	line-height: r(32px)
	@apply font-bold uppercase text-primary-222222
	@media (min-width: 1280px)
		font-size: r(32px)
		line-height: r(47px)

.medium-title
	font-size: r(20px)
	line-height: r(29px)
	@apply font-bold text-primary-FFFFFF text-center uppercase
	@media (min-width: 1280px)
		font-size: r(48px)
		line-height: r(70px)
