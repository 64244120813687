#main-banner
	.box-image
		.img
			a
				+img-ratio(552/1440)
				@media screen and ( max-width: 640px )
					+img-ratio(651/375)
	.swiper-pagination
		text-align: left
		padding-left: r(24px)
		bottom: r(40px) !important
		@media (min-width: 1280px)
			bottom: r(40px) !important
		span
			width: r(16px)
			height: r(16px)
			border: r(1px) solid transparent
			background: rgba($color: #ffffff, $alpha: .5)
			@apply rounded-full transition-all
			&.swiper-pagination-bullet-active
				@apply bg-primary-2D853D
	.wrap-content-list
		left: 0
		bottom: r(96px)
		z-index: 100
		ul
			li
				width: r(225px)
				height: r(88px)
				background: rgba(255, 255, 255, 0.7)
				backdrop-filter: blur(5.5px)
				border-radius: 0px r(82.5px) r(82.5px) 0px
				padding-right: r(11px)
				@apply mb-2
				a
					height: 100%
					width: 100%
					padding: r(11px) r(11px) r(11px) r(22px)
					font-size: r(22px)
					@apply flex justify-between items-center font-extrabold
					.circle-arrow
						width: r(28px)
						height: r(28px)
						@apply rounded-full bg-primary-FFFFFF flex items-center justify-center
