#page-banner,
#page-banner-detail
	.page-banner
		.img
			+img-ratio(552/1440)
			#icon_play
				position: absolute
				top: 50%
				left: 50%
				z-index: 3
				width: 123px
				height: 123px
				cursor: pointer
				transform: translate(-50%, -50%)
			@media screen and ( max-width: 640px )
				+img-ratio(210/375)
