#buddleChat
	position: fixed
	top: 75%
	right: r(32px)
	z-index: 10000
	width: r(96px)
	height: r(96px)
	padding: r(12.8px)
	background: #FFFFFF
	box-shadow: 0px 3.2px 16px rgba(0, 0, 0, 0.2)
	border-radius: r(32px)
	cursor: pointer
	.buddle-chat_main
		.img
			img
				width: r(47px)
				height: r(47px)
	@media screen and ( max-width: 640px )
		right: 16px
		box-shadow: 0px 1.93333px 9.66667px rgba(0, 0, 0, 0.2)
