.dialog-default
	border-radius: 20px
	display: none
	padding: r(24px) !important
	.is-close
		display: none
	.dialogBody
		.form-group
			height: 40px
			input
				height: 100%
				outline: none !important
			.icon-filled
				width: 24px
				height: 24px
	.dialogFooter
		button
			&:hover
				span
					img
						filter: brightness(0) invert(1)
#dialogTuVan
	min-width: 375px
	@media screen and ( max-width: 640px )
		min-width: 350px
	.dialogBody
		textarea
			height: r(136px)
			font-weight: 400
			outline: none !important
		.select2-container
			width: 100% !important
		.select2-container--default .select2-selection--single
			@apply pl-10
		.select2-container--default .select2-selection--single .select2-selection__rendered
			padding: 0 !important
			width: 100%
		.select2-container--default .select2-selection--single .select2-selection__arrow
			width: 24px !important
			height: 24px !important
			left: 0 !important
			top: 50%
			transform: translateY(-50%)
			&::after
				content: url('../img/icons/arrow-down-2.svg')
				width: 24px
				height: 24px
			b
				display: none
		.select2-container--open .select2-dropdown--below
			border: 0 !important
			box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)
		.select2-container--default .select2-results__option--highlighted[aria-selected]
			background-color: #2D853D
