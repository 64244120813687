.home-4
	.home-4-slide
		.swiper
			padding-bottom: 40px !important
		.card
			border-radius: 20px
			box-shadow: 1.21795px 1.21795px 6.08974px rgba(0, 0, 0, 0.07)
			.thumbnail
				border-radius: 20px 20px 0 0
				+img-ratio(228/400)
				img
					border-radius: 20px 20px 0 0
			.content-text
				height: r(200px)
		.swiper-button-next,
		.swiper-button-prev
			width: 44px
			height: 44px
			border-radius: 50%
			background: #fff
			border: 1px solid rgba($color: #333333, $alpha: .5)
			&::after
				display: none
			@media screen and ( max-width: 1024px )
				display: none
		.swiper-button-next
			right: -60px
		.swiper-button-prev
			left: -60px
		.swiper-pagination
			text-align: center
			bottom: 0 !important
			span
				width: r(16px)
				height: r(16px)
				border: r(1px) solid transparent
				background: #ECECEC
				@apply rounded-full transition-all
				&.swiper-pagination-bullet-active
					@apply bg-primary-2D853D
