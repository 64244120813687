.arrow-button
	.swiper-button-disabled
		opacity: 0.5
		pointer-events: none
	.swiper-button-prev
		left: -calc(60/1920*100*1rem)
		@media (min-width: 1440px)
			left: r(-50px)
		&::after
			content: "\f177"
	.swiper-button-next
		right: -calc(60/1920*100*1rem)
		@media (min-width: 1440px)
			right: r(-50px)
		&::after
			content: "\f178"
	.swiper-button-prev,.swiper-button-next
		transition: .3s all ease-in-out
		margin: 0
		top: 50%
		background: #FFFFFF
		transform: translateY(-50%)
		border: r(2px) solid #CCCCCC
		width: r(50px)
		height: r(50px)
		@apply rounded-full
		@media (min-width: 1280px)
			width: r(64px)
			height: r(64px)
		&::after
			font-weight: 300
			color: #CCCCCC
			font-size: r(25px)
			line-height: r(25px)
			@media (min-width: 1280px)
				font-size: r(32px)
				line-height: r(32px)
		&:hover
			@apply bg-primary-051A3A border-primary-051A3A
			&::after
				color: #fff
	&.arrow-2
		.swiper-button-prev,.swiper-button-next
			@apply bg-transparent
			@media (min-width: 1280px)
				width: r(56px)
				height: r(56px)
			&:hover
				border: r(2px) solid #CCCCCC

	@media (max-width: 1279.98px)
		display: flex
		justify-content: center
		align-items: center
		position: relative
		.swiper-button-prev,.swiper-button-next
			margin-top: calc(10/1920*100*1rem)
			position: static
			transform: translateY(0)
		.swiper-button-next
			margin-left: calc(10/1920*100*1rem)
	&.no-responsive
		@media (max-width: 1280px)
			display: contents
		.swiper-button-prev,.swiper-button-next
			position: absolute !important
			margin: 0 !important
			top: 50%
			transform: translateY(-50%)
		.swiper-button-prev
			@media (max-width: 1599.98px)
				left: -calc(40/1920*100*1rem)
			@media (max-width: 1366px)
				left: 0
		.swiper-button-next
			@media (max-width: 1599.98px)
				right: -calc(40/1920*100*1rem)
			@media (max-width: 1366px)
				right: 0
		@media (max-width: 1280px)
			.swiper-button-prev
				left: calc(10/1920*100*1rem)
			.swiper-button-next
				right: calc(10/1920*100*1rem)
	&.static-arrow-left
		@apply flex
		.swiper-button-prev,.swiper-button-next
			@apply static translate-y-0
		.swiper-button-next
			@apply ml-10px

.swiper-pagination
	bottom: -calc(20/1920*100*1rem) !important
	line-height: 0 !important
	span
		margin: 0 calc(4/1920*100*1rem)
		width: calc(12/1920*100*1rem)
		height: calc(12/1920*100*1rem)
		border: thin solid #338dcc
		background-color: transparent
		opacity: 1
		transition: .3s all ease-in-out
		&.swiper-pagination-bullet-active
			background-color: $main
