.global-breadcrumb
	background: #F9FAFC
	.rank-math-breadcrumb
		ul
			li
				display: flex
				align-items: center
				position: relative
				a
					@apply font-normal text-primary-6F7279 text-base
				&.active
					a
						@apply font-bold text-primary-222222 text-base
				+ li
					margin-left: r(24px)
					&::before
						content: url('../img/icons/arrow-right-4.svg')
						width: 24px
						height: 24px
						margin-right: 20px
