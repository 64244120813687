.ovh
	overflow: hidden

.loading-animation
	pointer-events: none
	user-select: none
	&::after
		content: ''
		position: absolute
		width: 100%
		left: 50%
		top: 50%
		transform: translate(-50%,-50%)
		padding: calc(5/1920*100*1rem) calc(10/1920*100*1rem)
		animation: loading 2s infinite forwards
		background: linear-gradient(to right, #fff 8%, #f5f5f5 18%, #fff 33%)
		background-size: 250% 350%
		height: 100%
		transition: .3s all ease-in-out
		pointer-events: none
		z-index: 100
	&.remove-loading
		pointer-events: visible
		user-select: auto
		&::after
			transition-delay: 0.3s
			opacity: 0

#fixed-tool
	position: fixed
	right: calc(15/1920*100*1rem)
	transition: .3s all ease-in-out
	bottom: 5vh
	z-index: 50
	@apply pointer-events-none opacity-0
	&.active
		bottom: 10vh
		@apply pointer-events-auto opacity-100
	a
		display: flex
		justify-content: center
		align-items: center
		width: calc(40/1920*100*1rem)
		height: calc(40/1920*100*1rem)
		border-radius: 1000%
		background-color: $main
		cursor: pointer
		transition: .3s all ease-in-out
		& + a
			margin-top: calc(10/1920*100*1rem)
		@media (min-width: 576px)
			width: calc(50/1920*100*1rem)
			height: calc(50/1920*100*1rem)
		em
			font-weight: 300
			font-size: r(24px)
			color: #fff
			transition: .3s all ease-in-out
		&:first-child
			background: rgba(#FFFFFF ,.5 )
			em
				color: #666666

		&:hover
			background-color: $main_2
			em
				color: #fff

// => Edit Fixed Tool
.editmode
	.Module-1408
		height: calc(150/1920*100*1rem)
		margin-top: calc(15/1920*100*1rem)
		background-color: $main_2
		color: #fff
		@apply w-full flex items-center justify-center
		&::before
			content: 'Edit Fixed Tool'
			@apply text-center text-2xl text-white font-semibold

.desktop-show
	@apply hidden md:block
.mobile-show
	@apply block md:hidden

.search-overlay
	z-index: 1030
	top: var(--header-height)
	height: 20vh
	@apply bg-secondary-224376 shadow-lg opacity-0 pointer-events-none transition-all fixed left-0 w-full flex items-center justify-center
	@media (min-width: 1280px)
		height: 50vh
	&.active
		@apply opacity-100 pointer-events-auto
		.searchbox
			transform: translateY(0%)
			@apply delay-100 opacity-100
		.close
			@apply opacity-100
	.close
		z-index: 2
		bottom: 100%
		width: r(40px)
		height: r(40px)
		@apply flex justify-center pointer-events-none absolute items-center opacity-0 transition-all duration-1000
		i
			font-size: r(30px)
			@apply text-white justify-center
	.searchbox
		transform: translateY(100%)
		@apply flex h-13 transition-all relative duration-1000 opacity-0
		input
			@apply bg-transparent border-b border-black xl:border-white w-[calc(100%-calc(56/1920*100*1rem))] text-3xl xl:text-white  rounded-none placeholder:text-base placeholder:text-white font-light p-5
		button
			@apply pl-4 h-full transition-all
			em
				@apply w-13 text-xl xl:text-white font-light border border-black xl:border-white rounded-sm flex items-center justify-center rounded-sm h-full transition-all text-black
			&:hover,&:active
				em
					@apply bg-primary-051A3A
.tabslet-content
	@apply hidden

#overlay
	@apply z-10 fixed top-0 left-0 w-full h-full bg-transparent transition-all pointer-events-none xl:hidden
	&.active
		@apply bg-black/50 pointer-events-auto

.list-item-added
	list-style: disc
	padding-left: 15px
	@media (min-width: 1280px)
		padding-left: r(17px)
	li
		&::marker
			@apply text-999

.icon-80
	width: r(60px)
	height: r(60px)
	@media (min-width: 1280px)
		width: r(80px)
		height: r(80px)
	i
		font-weight: 300
		font-size: r(25px)
		line-height: r(25px)
		@apply text-white
		@media (min-width: 1280px)
			font-size: r(32px)
			line-height: r(32px)

.tag-item
	padding: r(6px) r(12px)
	background: #D2DDEE
	@apply text-secondary-2056A9 text-sm leading-18px font-normal w-fit
body
	&:not(.homepage)
		.form-group
			input[type="text"],input[type="tel"],input[type="email"],input[type="number"],input[type="date"],textarea,select
				background: #FFFFFF
				border: r(1px) solid #CEDEF8
				border-radius: r(5px)
				padding: r(7px) r(20px)
				padding: r(12.5px) r(20px)
				@apply text-base leading-18px text-444 placeholder:text-base placeholder:text-444 w-full block

.select2-container--default .select2-selection--single
	border: 0 !important
	background: transparent !important
.lcl
	overflow: hidden
	display: -webkit-box
	text-overflow: ellipsis
	-webkit-box-orient: vertical

	&-1
		-webkit-line-clamp: 1

	&-2
		-webkit-line-clamp: 2

	&-3
		-webkit-line-clamp: 3

	&-4
		-webkit-line-clamp: 4

	&-5
		-webkit-line-clamp: 5
.container--tabs
	.nav-tabs
		li
			&.active
				a
					@apply font-bold text-primary-2D853D relative
					&::after
						content: ''
						@apply w-full absolute bottom-0 left-0 border-b-2 border-primary-2D853D
	.tab-content
		.panel
			display: none
			&.active
				display: block
.back-to-top
	a
		width: 50px
		height: 50px
		border-radius: 50%
.pagination
	ul
		li
			a
				width: r(40px)
				height: r(40px)
				line-height: r(29px)
				@apply text-primary-6F7279
			&:hover
				a
					@apply text-primary-FFFFFF bg-primary-2D853D transition
.swiper-pagination
	text-align: center
	bottom: 0 !important
	span
		width: r(16px)
		height: r(16px)
		border: r(1px) solid transparent
		background: #ECECEC
		@apply rounded-full transition-all
		&.swiper-pagination-bullet-active
			@apply bg-primary-2D853D
.tag
	border-radius: r(20px)
	background: #FFF9E2
	@apply flex items-center justify-center font-bold text-base text-primary-333333 px-4 py-2
