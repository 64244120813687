@charset "UTF-8";
body, html {
  font-size: 13px;
}

@media (min-width: 576px) {
  body, html {
    font-size: 14px;
  }
}
@media (min-width: 1024px) {
  body, html {
    font-size: 15px;
  }
}
@media (min-width: 1280px) {
  body, html {
    font-size: 1vw;
  }
}
main {
  max-width: 100vw;
  overflow-x: hidden;
}

::selection {
  background-color: #001F5F;
  color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0;
  margin-left: 0;
}

blockquote {
  margin: 0;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  background-color: transparent;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

a:not([href]):not([tabindex]) {
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto;
}

figure {
  margin: 0;
}

img {
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0;
  padding-bottom: 0;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

button {
  border-radius: 0;
}

button:focus {
  outline: none;
  outline: calc(5/1920*100*1rem) auto -webkit-focus-ring-color;
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button, [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner, [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio], input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date], input[type=time], input[type=datetime-local], input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

input:focus, button:focus, a:focus, .button-prev:focus, .button-next:focus, textarea:focus, select:focus {
  outline: none;
}

img.noImage {
  padding: 0 !important;
  object-fit: cover !important;
}

.zoom-img, .bn {
  overflow: hidden;
}

.zoom-img .img, .bn .img {
  overflow: hidden;
}

.zoom-img:hover img, .bn:hover img {
  transform: scale(1.05);
}

.zoom-img img, .bn img {
  transition: 0.3s all ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
}

.btn:focus {
  outline: none !important;
  box-shadow: initial !important;
}

.btn-toggle {
  margin-left: 0;
  cursor: pointer;
  position: relative;
  padding: 0;
  min-width: 20px;
  min-height: 13.5px;
  transition: 0.3s all linear;
}

.btn-toggle span {
  display: block;
  position: absolute;
  height: 0.15625rem;
  width: 100%;
  background: #58595B;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.15s ease-in-out;
}

.btn-toggle span:nth-child(1) {
  top: 0;
}

.btn-toggle span:nth-child(2), .btn-toggle span:nth-child(3) {
  top: 5px;
}

.btn-toggle span:nth-child(4) {
  top: 10px;
}

.btn-toggle.active span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.btn-toggle.active span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.btn-toggle.active span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.btn-toggle.active span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.btn-view-more {
  border: 0.608974px solid #333333;
  border-radius: 30.4487px;
  font-size: 10px;
  line-height: 15px;
  height: 23px;
  font-weight: 700;
  color: #333333;
}

.section {
  padding: 35px 0;
}

@media (min-width: 768px) {
  .section {
    padding: 1.25rem 0;
  }
}
@media (min-width: 1024px) {
  .section {
    padding: 1.6666666667rem 0;
  }
}
@media (min-width: 1280px) {
  .section {
    padding: 2.0833333333rem 0;
  }
}
.arrow-button .swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.arrow-button .swiper-button-prev {
  left: -calc(3.125rem);
}

@media (min-width: 1440px) {
  .arrow-button .swiper-button-prev {
    left: -2.6041666667rem;
  }
}
.arrow-button .swiper-button-prev::after {
  content: "";
}

.arrow-button .swiper-button-next {
  right: -calc(3.125rem);
}

@media (min-width: 1440px) {
  .arrow-button .swiper-button-next {
    right: -2.6041666667rem;
  }
}
.arrow-button .swiper-button-next::after {
  content: "";
}

.arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
  transition: 0.3s all ease-in-out;
  margin: 0;
  top: 50%;
  background: #FFFFFF;
  transform: translateY(-50%);
  border: 0.1041666667rem solid #CCCCCC;
  width: 2.6041666667rem;
  height: 2.6041666667rem;
  @apply rounded-full;
}

@media (min-width: 1280px) {
  .arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
    width: 3.3333333333rem;
    height: 3.3333333333rem;
  }
}
.arrow-button .swiper-button-prev::after, .arrow-button .swiper-button-next::after {
  font-weight: 300;
  color: #CCCCCC;
  font-size: 1.3020833333rem;
  line-height: 1.3020833333rem;
}

@media (min-width: 1280px) {
  .arrow-button .swiper-button-prev::after, .arrow-button .swiper-button-next::after {
    font-size: 1.6666666667rem;
    line-height: 1.6666666667rem;
  }
}
.arrow-button .swiper-button-prev:hover, .arrow-button .swiper-button-next:hover {
  @apply bg-primary-051A3A border-primary-051A3A;
}

.arrow-button .swiper-button-prev:hover::after, .arrow-button .swiper-button-next:hover::after {
  color: #fff;
}

.arrow-button.arrow-2 .swiper-button-prev, .arrow-button.arrow-2 .swiper-button-next {
  @apply bg-transparent;
}

@media (min-width: 1280px) {
  .arrow-button.arrow-2 .swiper-button-prev, .arrow-button.arrow-2 .swiper-button-next {
    width: 2.9166666667rem;
    height: 2.9166666667rem;
  }
}
.arrow-button.arrow-2 .swiper-button-prev:hover, .arrow-button.arrow-2 .swiper-button-next:hover {
  border: 0.1041666667rem solid #CCCCCC;
}

@media (max-width: 1279.98px) {
  .arrow-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
    margin-top: calc(10/1920*100*1rem);
    position: static;
    transform: translateY(0);
  }

  .arrow-button .swiper-button-next {
    margin-left: calc(10/1920*100*1rem);
  }
}
@media (max-width: 1280px) {
  .arrow-button.no-responsive {
    display: contents;
  }
}
.arrow-button.no-responsive .swiper-button-prev, .arrow-button.no-responsive .swiper-button-next {
  position: absolute !important;
  margin: 0 !important;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1599.98px) {
  .arrow-button.no-responsive .swiper-button-prev {
    left: -calc(2.0833333333rem);
  }
}
@media (max-width: 1366px) {
  .arrow-button.no-responsive .swiper-button-prev {
    left: 0;
  }
}
@media (max-width: 1599.98px) {
  .arrow-button.no-responsive .swiper-button-next {
    right: -calc(2.0833333333rem);
  }
}
@media (max-width: 1366px) {
  .arrow-button.no-responsive .swiper-button-next {
    right: 0;
  }
}
@media (max-width: 1280px) {
  .arrow-button.no-responsive .swiper-button-prev {
    left: calc(10/1920*100*1rem);
  }

  .arrow-button.no-responsive .swiper-button-next {
    right: calc(10/1920*100*1rem);
  }
}
.arrow-button.static-arrow-left {
  @apply flex;
}

.arrow-button.static-arrow-left .swiper-button-prev, .arrow-button.static-arrow-left .swiper-button-next {
  @apply static translate-y-0;
}

.arrow-button.static-arrow-left .swiper-button-next {
  @apply ml-10px;
}

.swiper-pagination {
  bottom: -calc(1.0416666667rem) !important;
  line-height: 0 !important;
}

.swiper-pagination span {
  margin: 0 calc(4/1920*100*1rem);
  width: calc(12/1920*100*1rem);
  height: calc(12/1920*100*1rem);
  border: thin solid #338dcc;
  background-color: transparent;
  opacity: 1;
  transition: 0.3s all ease-in-out;
}

.swiper-pagination span.swiper-pagination-bullet-active {
  background-color: #001F5F;
}

.block-title {
  font-size: 1.25rem;
  line-height: 1.6666666667rem;
  @apply font-bold uppercase text-primary-222222;
}

@media (min-width: 1280px) {
  .block-title {
    font-size: 1.6666666667rem;
    line-height: 2.4479166667rem;
  }
}
.medium-title {
  font-size: 1.0416666667rem;
  line-height: 1.5104166667rem;
  @apply font-bold text-primary-FFFFFF text-center uppercase;
}

@media (min-width: 1280px) {
  .medium-title {
    font-size: 2.5rem;
    line-height: 3.6458333333rem;
  }
}
.ovh {
  overflow: hidden;
}

.loading-animation {
  pointer-events: none;
  user-select: none;
}

.loading-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: calc(5/1920*100*1rem) calc(10/1920*100*1rem);
  animation: loading 2s infinite forwards;
  background: linear-gradient(to right, #fff 8%, #f5f5f5 18%, #fff 33%);
  background-size: 250% 350%;
  height: 100%;
  transition: 0.3s all ease-in-out;
  pointer-events: none;
  z-index: 100;
}

.loading-animation.remove-loading {
  pointer-events: visible;
  user-select: auto;
}

.loading-animation.remove-loading::after {
  transition-delay: 0.3s;
  opacity: 0;
}

#fixed-tool {
  position: fixed;
  right: calc(15/1920*100*1rem);
  transition: 0.3s all ease-in-out;
  bottom: 5vh;
  z-index: 50;
  @apply pointer-events-none opacity-0;
}

#fixed-tool.active {
  bottom: 10vh;
  @apply pointer-events-auto opacity-100;
}

#fixed-tool a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(40/1920*100*1rem);
  height: calc(40/1920*100*1rem);
  border-radius: 1000%;
  background-color: #001F5F;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

#fixed-tool a + a {
  margin-top: calc(10/1920*100*1rem);
}

@media (min-width: 576px) {
  #fixed-tool a {
    width: calc(50/1920*100*1rem);
    height: calc(50/1920*100*1rem);
  }
}
#fixed-tool a em {
  font-weight: 300;
  font-size: 1.25rem;
  color: #fff;
  transition: 0.3s all ease-in-out;
}

#fixed-tool a:first-child {
  background: rgba(255, 255, 255, 0.5);
}

#fixed-tool a:first-child em {
  color: #666666;
}

#fixed-tool a:hover {
  background-color: #015464;
}

#fixed-tool a:hover em {
  color: #fff;
}

.editmode .Module-1408 {
  height: calc(150/1920*100*1rem);
  margin-top: calc(15/1920*100*1rem);
  background-color: #015464;
  color: #fff;
  @apply w-full flex items-center justify-center;
}

.editmode .Module-1408::before {
  content: "Edit Fixed Tool";
  @apply text-center text-2xl text-white font-semibold;
}

.desktop-show {
  @apply hidden md:block;
}

.mobile-show {
  @apply block md:hidden;
}

.search-overlay {
  z-index: 1030;
  top: var(--header-height);
  height: 20vh;
  @apply bg-secondary-224376 shadow-lg opacity-0 pointer-events-none transition-all fixed left-0 w-full flex items-center justify-center;
}

@media (min-width: 1280px) {
  .search-overlay {
    height: 50vh;
  }
}
.search-overlay.active {
  @apply opacity-100 pointer-events-auto;
}

.search-overlay.active .searchbox {
  transform: translateY(0%);
  @apply delay-100 opacity-100;
}

.search-overlay.active .close {
  @apply opacity-100;
}

.search-overlay .close {
  z-index: 2;
  bottom: 100%;
  width: 2.0833333333rem;
  height: 2.0833333333rem;
  @apply flex justify-center pointer-events-none absolute items-center opacity-0 transition-all duration-1000;
}

.search-overlay .close i {
  font-size: 1.5625rem;
  @apply text-white justify-center;
}

.search-overlay .searchbox {
  transform: translateY(100%);
  @apply flex h-13 transition-all relative duration-1000 opacity-0;
}

.search-overlay .searchbox input {
  @apply bg-transparent border-b border-black xl:border-white w-[calc(100%-calc(56/1920*100*1rem))] text-3xl xl:text-white  rounded-none placeholder:text-base placeholder:text-white font-light p-5;
}

.search-overlay .searchbox button {
  @apply pl-4 h-full transition-all;
}

.search-overlay .searchbox button em {
  @apply w-13 text-xl xl:text-white font-light border border-black xl:border-white rounded-sm flex items-center justify-center rounded-sm h-full transition-all text-black;
}

.search-overlay .searchbox button:hover em, .search-overlay .searchbox button:active em {
  @apply bg-primary-051A3A;
}

.tabslet-content {
  @apply hidden;
}

#overlay {
  @apply z-10 fixed top-0 left-0 w-full h-full bg-transparent transition-all pointer-events-none xl:hidden;
}

#overlay.active {
  @apply bg-black/50 pointer-events-auto;
}

.list-item-added {
  list-style: disc;
  padding-left: 15px;
}

@media (min-width: 1280px) {
  .list-item-added {
    padding-left: 0.8854166667rem;
  }
}
.list-item-added li::marker {
  @apply text-999;
}

.icon-80 {
  width: 3.125rem;
  height: 3.125rem;
}

@media (min-width: 1280px) {
  .icon-80 {
    width: 4.1666666667rem;
    height: 4.1666666667rem;
  }
}
.icon-80 i {
  font-weight: 300;
  font-size: 1.3020833333rem;
  line-height: 1.3020833333rem;
  @apply text-white;
}

@media (min-width: 1280px) {
  .icon-80 i {
    font-size: 1.6666666667rem;
    line-height: 1.6666666667rem;
  }
}
.tag-item {
  padding: 0.3125rem 0.625rem;
  background: #D2DDEE;
  @apply text-secondary-2056A9 text-sm leading-18px font-normal w-fit;
}

body:not(.homepage) .form-group input[type=text], body:not(.homepage) .form-group input[type=tel], body:not(.homepage) .form-group input[type=email], body:not(.homepage) .form-group input[type=number], body:not(.homepage) .form-group input[type=date], body:not(.homepage) .form-group textarea, body:not(.homepage) .form-group select {
  background: #FFFFFF;
  border: 0.0520833333rem solid #CEDEF8;
  border-radius: 0.2604166667rem;
  padding: 0.3645833333rem 1.0416666667rem;
  padding: 0.6510416667rem 1.0416666667rem;
  @apply text-base leading-18px text-444 placeholder:text-base placeholder:text-444 w-full block;
}

.select2-container--default .select2-selection--single {
  border: 0 !important;
  background: transparent !important;
}

.lcl {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.lcl-1 {
  -webkit-line-clamp: 1;
}

.lcl-2 {
  -webkit-line-clamp: 2;
}

.lcl-3 {
  -webkit-line-clamp: 3;
}

.lcl-4 {
  -webkit-line-clamp: 4;
}

.lcl-5 {
  -webkit-line-clamp: 5;
}

.container--tabs .nav-tabs li.active a {
  @apply font-bold text-primary-2D853D relative;
}

.container--tabs .nav-tabs li.active a::after {
  content: "";
  @apply w-full absolute bottom-0 left-0 border-b-2 border-primary-2D853D;
}

.container--tabs .tab-content .panel {
  display: none;
}

.container--tabs .tab-content .panel.active {
  display: block;
}

.back-to-top a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.pagination ul li a {
  width: 2.0833333333rem;
  height: 2.0833333333rem;
  line-height: 1.5104166667rem;
  @apply text-primary-6F7279;
}

.pagination ul li:hover a {
  @apply text-primary-FFFFFF bg-primary-2D853D transition;
}

.swiper-pagination {
  text-align: center;
  bottom: 0 !important;
}

.swiper-pagination span {
  width: 0.8333333333rem;
  height: 0.8333333333rem;
  border: 0.0520833333rem solid transparent;
  background: #ECECEC;
  @apply rounded-full transition-all;
}

.swiper-pagination span.swiper-pagination-bullet-active {
  @apply bg-primary-2D853D;
}

.tag {
  border-radius: 1.0416666667rem;
  background: #FFF9E2;
  @apply flex items-center justify-center font-bold text-base text-primary-333333 px-4 py-2;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  *,
*::before,
*::after {
    box-sizing: border-box;
  }

  *::-webkit-scrollbar-track {
    @apply bg-primary-FFC20E;
  }

  *::-webkit-scrollbar {
    width: 0.4166666667rem;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 0;
    border: 0.1041666667rem solid #999;
    @apply bg-primary-2D853D;
  }

  html {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    line-height: 1.5;
    font-weight: 400;
    color: #111;
    @apply font-body;
  }

  img {
    display: inline;
  }
}
.news-icon {
  @apply flex text-shadow-DEFAULT;
}

.news-icon .title {
  @apply font-normal text-xl leading-26px text-primary-051A3A;
}

.news-icon .icon {
  flex: 0 0 1.25rem;
}

.news-icon .icon i {
  font-size: 1.6666666667rem;
  line-height: 1.6666666667rem;
  @apply text-secondary-224376 font-thin;
}

.news-icon .content {
  @apply ml-15px;
}

.news-icon .info {
  @apply font-normal text-sm leading-18px text-999 flex items-center text-shadow-DEFAULT;
}

.news-icon .info .category {
  margin-left: 0.4166666667rem;
  padding-left: 0.4166666667rem;
  @apply relative;
}

.news-icon .info .category::before {
  content: "";
  width: 0.0520833333rem;
  height: 0.5729166667rem;
  background: #E6E6E6;
  @apply absolute left-0 top-1/2 -translate-y-1/2;
}

.news-icon-list .news-icon + .news-icon {
  padding-top: 0.6770833333rem;
  margin-top: 0.6770833333rem;
  border-top: 0.0520833333rem solid #E6E6E6;
}

.bn .title {
  @apply transition-all font-bold text-2xl leading-26px;
}

.bn .date {
  @apply font-normal text-sm leading-18px;
}

.bn.bn-1 .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 57.7777777778%;
}

.bn.bn-1 .img a img, .bn.bn-1 .img a iframe, .bn.bn-1 .img a video, .bn.bn-1 .img a canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.bn.bn-1 .box-content {
  @apply mt-5 lg:mt-6;
}

.bn.bn-1 .date {
  @apply mt-10px mb-5;
}

.bn.bn-1 .title {
  @apply text-primary-051A3A;
}

.bn.bn-1 .ctn {
  @apply font-normal text-lg leading-24px;
}

.bn.bn-1:hover .title {
  @apply text-primary-BB9C66;
}

.bn.bn-2 {
  @apply relative;
}

.bn.bn-2 .img {
  @apply relative;
}

.bn.bn-2 .img::before {
  content: "";
  height: 18.4375rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  opacity: 0.7;
  height: 60%;
  z-index: 2;
  @apply absolute bottom-0 left-0 w-full pointer-events-none;
}

.bn.bn-2 .box-content {
  padding: 1.0416666667rem;
  @apply absolute bottom-0 left-0 w-full z-5;
}

@media (min-width: 1024px) {
  .bn.bn-2 .box-content {
    padding: 1.5625rem;
  }
}
.bn.bn-2 .box-content .title, .bn.bn-2 .box-content .date {
  @apply text-white;
}

.bn.bn-2 .date {
  @apply mt-10px;
}

.bn.bn-2.big .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 63.4408602151%;
}

.bn.bn-2.big .img a img, .bn.bn-2.big .img a iframe, .bn.bn-2.big .img a video, .bn.bn-2.big .img a canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.bn.bn-2.small .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 62.2222222222%;
}

.bn.bn-2.small .img a img, .bn.bn-2.small .img a iframe, .bn.bn-2.small .img a video, .bn.bn-2.small .img a canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

@media (max-width: 1023.98px) {
  .bn.bn-2.small .box-content {
    padding: 0;
    @apply static mt-5;
  }

  .bn.bn-2.small .box-content .title, .bn.bn-2.small .box-content .date {
    @apply text-black;
  }
}
.bn.bn-2:hover .title {
  @apply text-primary-BB9C66;
}

.project .card,
.news .card {
  background: rgba(255, 255, 255, 0.94);
  box-shadow: 1.21795px 1.21795px 6.08974px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
}

.project .card .card-img,
.news .card .card-img {
  border-radius: 20px 20px 0 0;
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 57%;
}

.project .card .card-img img, .project .card .card-img iframe, .project .card .card-img video, .project .card .card-img canvas,
.news .card .card-img img,
.news .card .card-img iframe,
.news .card .card-img video,
.news .card .card-img canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.project .sigle-slide .card,
.news .sigle-slide .card {
  border-radius: 20px;
  background: #FFFFFF;
  box-shadow: 1.21795px 1.21795px 12px rgba(0, 0, 0, 0.2);
}

.project .sigle-slide .card .thumbnail,
.news .sigle-slide .card .thumbnail {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 57.1428571429%;
}

.project .sigle-slide .card .thumbnail img, .project .sigle-slide .card .thumbnail iframe, .project .sigle-slide .card .thumbnail video, .project .sigle-slide .card .thumbnail canvas,
.news .sigle-slide .card .thumbnail img,
.news .sigle-slide .card .thumbnail iframe,
.news .sigle-slide .card .thumbnail video,
.news .sigle-slide .card .thumbnail canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.news-detail .article .other-article .card {
  background: rgba(255, 255, 255, 0.94);
  border-radius: 20px;
  box-shadow: 1.21795px 1.21795px 6.08974px rgba(0, 0, 0, 0.07);
}

.news-detail .article .other-article .card .card-img {
  border-radius: 20px 20px 0 0;
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 57%;
}

.news-detail .article .other-article .card .card-img img, .news-detail .article .other-article .card .card-img iframe, .news-detail .article .other-article .card .card-img video, .news-detail .article .other-article .card .card-img canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.project-detail .wrap-container .button-link a {
  width: 44px;
  height: 44px;
  border: 1px solid #333333;
  border-radius: 50%;
}

@media screen and (max-width: 640px) {
  .project-detail .wrap-container .wrap-desc p {
    letter-spacing: 0.01em;
    line-height: 26px;
    font-size: 18px;
    padding: 0;
  }
}
.project-detail .container--tabs .nav-tabs {
  border-radius: 12px;
}

.project-detail .container--tabs .nav-tabs li {
  border: 1.4px solid #DDE1EB;
  height: 100%;
}

.project-detail .container--tabs .nav-tabs li a {
  padding: 0.7291666667rem 1.5625rem;
  height: 100%;
}

.project-detail .container--tabs .nav-tabs li:first-child {
  border-radius: 12px 0 0 12px;
  border-right: 1.4px solid transparent;
}

.project-detail .container--tabs .nav-tabs li:first-child a {
  border-radius: 12px 0 0 12px;
}

.project-detail .container--tabs .nav-tabs li:last-child {
  border-radius: 0 12px 12px 0;
}

.project-detail .container--tabs .nav-tabs li:last-child a {
  border-radius: 0 12px 12px 0;
}

.project-detail .container--tabs .nav-tabs li:nth-child(2) {
  border-right: 1.4px solid transparent;
}

.project-detail .container--tabs .nav-tabs li:nth-child(3) {
  border-right: 1.4px solid transparent;
}

.project-detail .container--tabs .nav-tabs li.active a {
  @apply font-bold text-primary-FFFFFF bg-primary-2D853D border-0;
}

.project-detail .container--tabs .tab-content .panel {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
}

.project-detail .container--tabs .tab-content .panel-header {
  padding-bottom: 10px;
}

.project-detail .container--tabs .tab-content .panel-header::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 15.625rem;
  height: 1px;
  background: #222222;
}

.project-detail .container--tabs .tab-content .panel .card .thumbnail {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 57%;
}

.project-detail .container--tabs .tab-content .panel .card .thumbnail img, .project-detail .container--tabs .tab-content .panel .card .thumbnail iframe, .project-detail .container--tabs .tab-content .panel .card .thumbnail video, .project-detail .container--tabs .tab-content .panel .card .thumbnail canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

@media screen and (max-width: 640px) {
  .service {
    background: none !important;
  }
}
.service .accordionjs {
  position: static;
  width: 17.8645833333rem;
}

.service .accordionjs .acc_section {
  position: static;
  @apply border-0;
}

.service .accordionjs .acc_section .acc_head {
  transition: all 0.3s;
  border-radius: 1.0416666667rem;
  @apply flex items-center justify-between;
}

.service .accordionjs .acc_section .acc_head:hover {
  @apply bg-primary-rgba;
}

.service .accordionjs .acc_section .acc_head:hover h5,
.service .accordionjs .acc_section .acc_head:hover .material-icons-outlined {
  @apply text-primary-2D853D;
}

.service .accordionjs .acc_section .sub-nav > .accordionjs {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  margin-bottom: 0;
}

.service .accordionjs .acc_section #block-right_content {
  width: 27.6041666667rem;
  right: 0;
  top: 0;
}

.service .accordionjs .acc_section.acc_active > .acc_head .material-icons-outlined {
  @apply rotate-180 transition-transform;
}

@media screen and (max-width: 640px) {
  .service .accordionjs {
    width: 100%;
  }
}
.contact .block-left ul li {
  margin-bottom: 1.25rem;
}

.contact .block-left ul li div span img {
  width: 1.6666666667rem;
  height: 1.6666666667rem;
}

.contact .block-left .infor ul li {
  margin-bottom: 0;
}

.contact .block-left .infor ul li:last-child {
  position: relative;
  display: flex;
  align-items: center;
}

.contact .block-left .infor ul li:last-child::before {
  content: "";
  font-family: Material Icons;
  font-size: 0.5208333333rem;
  margin-right: 10px;
}

.contact .block-right .form-wrap {
  border-radius: 20px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
}

.contact .block-right .form-wrap h5 {
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #BCC0CC;
}

.contact .block-right .form-wrap .form-group {
  width: 100%;
  height: 3.0208333333rem;
}

.contact .block-right .form-wrap .form-group input {
  width: 100%;
  height: 100%;
}

.contact .block-right .form-wrap button:hover img {
  filter: brightness(0) invert(1);
}

@media screen and (max-width: 640px) {
  .contact .block-right .form-wrap {
    border-radius: 0;
    box-shadow: none;
    padding: 1.25rem 0;
  }
}
.news-detail .article .article-body .full-content p {
  @apply text-base;
}

.news-detail .article .article-body .full-content img {
  @apply my-4;
}

#main-banner .news-banner .box-image .img::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 62.39%);
  z-index: 999;
}

@media screen and (max-width: 640px) {
  #main-banner .news-banner .box-image .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 56%;
  }

  #main-banner .news-banner .box-image .img a img, #main-banner .news-banner .box-image .img a iframe, #main-banner .news-banner .box-image .img a video, #main-banner .news-banner .box-image .img a canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
#main-banner .news-banner .wrap-content-list {
  left: 4.1666666667rem;
  max-width: 43.9583333333rem;
  width: 100%;
  z-index: 1000;
}

#main-banner .news-banner .swiper-pagination {
  left: 4.1666666667rem;
}

.news .search-wrap {
  max-width: 41.6666666667rem;
  width: 100%;
  height: 3.0208333333rem;
}

.news .search-wrap button {
  position: absolute;
  top: 0.78125rem;
  right: 1.0416666667rem;
}

.news .box-item .thumbnail {
  min-width: 170px;
  height: 98px;
}

.news .box-item .thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 640px) {
  .news .box-item .thumbnail {
    min-width: 112px;
    height: 64px;
  }
}
@media screen and (max-width: 640px) {
  .news .box-item .box-centent .desc {
    display: none;
  }
}
@media screen and (max-width: 640px) {
  .news .box-item {
    border-bottom: 1px dashed #DDE1EB;
  }

  .news .box-item:last-child {
    border: 0;
  }
}
.global-breadcrumb {
  background: #F9FAFC;
}

.global-breadcrumb .rank-math-breadcrumb ul li {
  display: flex;
  align-items: center;
  position: relative;
}

.global-breadcrumb .rank-math-breadcrumb ul li a {
  @apply font-normal text-primary-6F7279 text-base;
}

.global-breadcrumb .rank-math-breadcrumb ul li.active a {
  @apply font-bold text-primary-222222 text-base;
}

.global-breadcrumb .rank-math-breadcrumb ul li + li {
  margin-left: 1.25rem;
}

.global-breadcrumb .rank-math-breadcrumb ul li + li::before {
  content: url("../img/icons/arrow-right-4.svg");
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

#main-banner .box-image .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 38.3333333333%;
}

#main-banner .box-image .img a img, #main-banner .box-image .img a iframe, #main-banner .box-image .img a video, #main-banner .box-image .img a canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

@media screen and (max-width: 640px) {
  #main-banner .box-image .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 173.6%;
  }

  #main-banner .box-image .img a img, #main-banner .box-image .img a iframe, #main-banner .box-image .img a video, #main-banner .box-image .img a canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
#main-banner .swiper-pagination {
  text-align: left;
  padding-left: 1.25rem;
  bottom: 2.0833333333rem !important;
}

@media (min-width: 1280px) {
  #main-banner .swiper-pagination {
    bottom: 2.0833333333rem !important;
  }
}
#main-banner .swiper-pagination span {
  width: 0.8333333333rem;
  height: 0.8333333333rem;
  border: 0.0520833333rem solid transparent;
  background: rgba(255, 255, 255, 0.5);
  @apply rounded-full transition-all;
}

#main-banner .swiper-pagination span.swiper-pagination-bullet-active {
  @apply bg-primary-2D853D;
}

#main-banner .wrap-content-list {
  left: 0;
  bottom: 5rem;
  z-index: 100;
}

#main-banner .wrap-content-list ul li {
  width: 11.71875rem;
  height: 4.5833333333rem;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5.5px);
  border-radius: 0px 4.296875rem 4.296875rem 0px;
  padding-right: 0.5729166667rem;
  @apply mb-2;
}

#main-banner .wrap-content-list ul li a {
  height: 100%;
  width: 100%;
  padding: 0.5729166667rem 0.5729166667rem 0.5729166667rem 1.1458333333rem;
  font-size: 1.1458333333rem;
  @apply flex justify-between items-center font-extrabold;
}

#main-banner .wrap-content-list ul li a .circle-arrow {
  width: 1.4583333333rem;
  height: 1.4583333333rem;
  @apply rounded-full bg-primary-FFFFFF flex items-center justify-center;
}

#page-banner .page-banner .img,
#page-banner-detail .page-banner .img {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 38.3333333333%;
}

#page-banner .page-banner .img img, #page-banner .page-banner .img iframe, #page-banner .page-banner .img video, #page-banner .page-banner .img canvas,
#page-banner-detail .page-banner .img img,
#page-banner-detail .page-banner .img iframe,
#page-banner-detail .page-banner .img video,
#page-banner-detail .page-banner .img canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

#page-banner .page-banner .img #icon_play,
#page-banner-detail .page-banner .img #icon_play {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  width: 123px;
  height: 123px;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 640px) {
  #page-banner .page-banner .img,
#page-banner-detail .page-banner .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 56%;
  }

  #page-banner .page-banner .img img, #page-banner .page-banner .img iframe, #page-banner .page-banner .img video, #page-banner .page-banner .img canvas,
#page-banner-detail .page-banner .img img,
#page-banner-detail .page-banner .img iframe,
#page-banner-detail .page-banner .img video,
#page-banner-detail .page-banner .img canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
#buddleChat {
  position: fixed;
  top: 75%;
  right: 1.6666666667rem;
  z-index: 10000;
  width: 5rem;
  height: 5rem;
  padding: 0.6666666667rem;
  background: #FFFFFF;
  box-shadow: 0px 3.2px 16px rgba(0, 0, 0, 0.2);
  border-radius: 1.6666666667rem;
  cursor: pointer;
}

#buddleChat .buddle-chat_main .img img {
  width: 2.4479166667rem;
  height: 2.4479166667rem;
}

@media screen and (max-width: 640px) {
  #buddleChat {
    right: 16px;
    box-shadow: 0px 1.93333px 9.66667px rgba(0, 0, 0, 0.2);
  }
}
.dialog-default {
  border-radius: 20px;
  display: none;
  padding: 1.25rem !important;
}

.dialog-default .is-close {
  display: none;
}

.dialog-default .dialogBody .form-group {
  height: 40px;
}

.dialog-default .dialogBody .form-group input {
  height: 100%;
  outline: none !important;
}

.dialog-default .dialogBody .form-group .icon-filled {
  width: 24px;
  height: 24px;
}

.dialog-default .dialogFooter button:hover span img {
  filter: brightness(0) invert(1);
}

#dialogTuVan {
  min-width: 375px;
}

@media screen and (max-width: 640px) {
  #dialogTuVan {
    min-width: 350px;
  }
}
#dialogTuVan .dialogBody textarea {
  height: 7.0833333333rem;
  font-weight: 400;
  outline: none !important;
}

#dialogTuVan .dialogBody .select2-container {
  width: 100% !important;
}

#dialogTuVan .dialogBody .select2-container--default .select2-selection--single {
  @apply pl-10;
}

#dialogTuVan .dialogBody .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding: 0 !important;
  width: 100%;
}

#dialogTuVan .dialogBody .select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 24px !important;
  height: 24px !important;
  left: 0 !important;
  top: 50%;
  transform: translateY(-50%);
}

#dialogTuVan .dialogBody .select2-container--default .select2-selection--single .select2-selection__arrow::after {
  content: url("../img/icons/arrow-down-2.svg");
  width: 24px;
  height: 24px;
}

#dialogTuVan .dialogBody .select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}

#dialogTuVan .dialogBody .select2-container--open .select2-dropdown--below {
  border: 0 !important;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
}

#dialogTuVan .dialogBody .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #2D853D;
}

footer .logo a {
  width: 129px;
}

@media screen and (max-width: 640px) {
  footer .logo a {
    width: 83px;
  }
}
footer .logo .back-to-top a {
  width: 40px;
  height: 40px;
}

footer #nav-footer li .menu-item-title p img {
  display: none;
  transition: all ease-in-out 0.3s;
  transform: rotate(0);
}

@media screen and (max-width: 640px) {
  footer #nav-footer li .menu-item-title p img {
    display: flex;
  }
}
footer #nav-footer li.active .menu-item-title p img {
  transform: rotate(180deg);
}

@media screen and (max-width: 640px) {
  footer #nav-footer li .sub-menu {
    display: none;
  }
}
footer .footer-content ul li::after {
  content: "";
  border: 1px solid rgba(34, 34, 34, 0.2);
  height: 20px;
  position: absolute;
  top: 50%;
  left: -18px;
  transform: translateY(-50%);
}

@media screen and (max-width: 640px) {
  footer .footer-content ul li:first-child {
    display: none;
  }
}
footer .footer-content ul li:first-child::after {
  display: none;
}

header .header-wrapper {
  z-index: 100;
}

header .header-wrapper .header-flex .block-left .main-nav nav ul li:hover a {
  transition: all ease-in-out 0.3s;
  font-weight: 700;
  @apply text-primary-FFC20E;
}

header .header-wrapper .header-flex .block-left .main-nav nav ul li.active a {
  font-weight: 700;
  @apply text-primary-2D853D;
}

header .header-wrapper .header-flex .block-left .main-nav.active {
  visibility: visible;
  opacity: 1;
  left: 0;
}

@media screen and (max-width: 640px) {
  header .header-wrapper .header-flex .block-left .main-nav {
    width: 16.1458333333rem;
    min-height: 42.2916666667rem;
    padding: 0.8333333333rem 1.25rem;
    top: 100%;
    left: -100%;
    margin-left: 0;
    visibility: hidden;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    @apply bg-primary-FFFFFF transition-all;
  }

  header .header-wrapper .header-flex .block-left .main-nav nav ul li {
    margin-bottom: 32px;
  }

  header .header-wrapper .header-flex .block-left .main-nav nav ul li a {
    font-size: 16px;
    font-weight: 700;
  }

  header .header-wrapper .header-flex .block-left .main-nav nav ul li:last-child a {
    font-weight: 400;
  }
}
header .header-wrapper .header-flex .block-right .group button .avatar {
  width: 2.5rem;
  height: 2.5rem;
}

header .header-wrapper .header-flex .block-right .group button .avatar img {
  width: 100%;
  height: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.overlay .overlayDoor:before, .overlay .overlayDoor:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  transition: 1s cubic-bezier(0.77, 0, 0.18, 1);
  @apply bg-gradient_2;
}

.overlay .overlayDoor:before {
  left: 0;
}

.overlay .overlayDoor:after {
  right: 0;
}

.overlay.loaded .overlayDoor:before {
  left: -50%;
}

.overlay.loaded .overlayDoor:after {
  right: -50%;
}

.overlay.loaded .overlayContent {
  opacity: 0;
  margin-top: -15px;
}

.overlay .overlayContent {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 1s cubic-bezier(0.77, 0, 0.18, 1);
}

.overlay .overlayContent .skip {
  display: block;
  width: 130px;
  text-align: center;
  margin: 50px auto 0;
  cursor: pointer;
  color: #fff;
  font-family: "Nunito";
  font-weight: 700;
  padding: 12px 0;
  border: 2px solid #fff;
  border-radius: 3px;
  transition: 1s ease;
}

.overlay .overlayContent .skip:hover {
  background: #ddd;
  color: #444;
  border-color: #ddd;
}

.loader {
  width: 128px;
  height: 128px;
  border: 3px solid #fff;
  border-bottom: 3px solid transparent;
  border-radius: 50%;
  position: relative;
  animation: spin 1s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader .inner {
  width: 64px;
  height: 64px;
  border: 3px solid transparent;
  border-top: 3px solid #fff;
  border-radius: 50%;
  animation: spinInner 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-720deg);
  }
}
.home-3 .container--tabs .desktop-show .promo-area .promo-shelt .card {
  border-radius: 1.0416666667rem;
  box-shadow: 0.0634348958rem 0.0634348958rem 0.3171739583rem rgba(0, 0, 0, 0.07);
}

.home-3 .container--tabs .desktop-show .promo-area .promo-shelt .card .thumbnail {
  border-radius: 1.0416666667rem 1.0416666667rem 0 0;
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 57%;
}

.home-3 .container--tabs .desktop-show .promo-area .promo-shelt .card .thumbnail img, .home-3 .container--tabs .desktop-show .promo-area .promo-shelt .card .thumbnail iframe, .home-3 .container--tabs .desktop-show .promo-area .promo-shelt .card .thumbnail video, .home-3 .container--tabs .desktop-show .promo-area .promo-shelt .card .thumbnail canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.home-3 .container--tabs .desktop-show .promo-area .promo-shelt .card .thumbnail img {
  border-radius: 1.0416666667rem 1.0416666667rem 0 0;
}

.home-3 .container--tabs .desktop-show .promo-area .promo-shelt .card .content-text {
  height: 8.8541666667rem;
}

.home-3 .container--tabs .mobile-show .promo-area .home-3-slide .card {
  box-shadow: 1.21795px 1.21795px 6.08974px rgba(0, 0, 0, 0.07);
}

.home-3 .container--tabs .mobile-show .promo-area .home-3-slide .card .thumbnail {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 57%;
  background: linear-gradient(0deg, #CCCCCC, #CCCCCC);
}

.home-3 .container--tabs .mobile-show .promo-area .home-3-slide .card .thumbnail img, .home-3 .container--tabs .mobile-show .promo-area .home-3-slide .card .thumbnail iframe, .home-3 .container--tabs .mobile-show .promo-area .home-3-slide .card .thumbnail video, .home-3 .container--tabs .mobile-show .promo-area .home-3-slide .card .thumbnail canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.home-3 .container--tabs .mobile-show .promo-area .home-3-slide .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.home-3 .container--tabs .wrapper {
  position: relative;
  overflow: hidden;
  height: calc(60/1920*100*1rem);
}

.home-3 .container--tabs .wrapper .list {
  position: absolute;
  left: 0px;
  top: 0px;
  margin-top: 0px;
  min-width: calc(1900/1920*100*1rem);
}

.home-3 .container--tabs .wrapper .list li {
  flex: 1 0 auto;
}

@media screen and (max-width: 1024px) {
  .home-3 .container--tabs .wrapper {
    @apply overflow-x-auto h-auto;
  }

  .home-3 .container--tabs .wrapper .list {
    position: static;
    min-width: 100%;
    padding: 0;
    padding-bottom: 16px;
  }
}
.home-3 .container--tabs .scroller-left {
  left: 0;
}

.home-3 .container--tabs .scroller-right {
  right: 0;
}

.home-3 .container--tabs .scroller-left, .home-3 .container--tabs .scroller-right {
  width: calc(40/1920*100*1rem);
  height: calc(40/1920*100*1rem);
  position: absolute;
  top: calc(16/1920*100*1rem);
  text-align: center;
  cursor: pointer;
  white-space: no-wrap;
  vertical-align: middle;
  display: none;
  align-items: center;
  justify-content: center;
  border: 1px solid #222222;
  background-color: #fff;
  z-index: 10;
  border-radius: 8px;
}

.home-3 .container--tabs .scroller-left em, .home-3 .container--tabs .scroller-right em {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .home-3 .container--tabs .wrapper::-webkit-scrollbar {
    height: 4px;
  }
}
.home-2 .block-card {
  border-radius: 1.0416666667rem;
}

.home-2 .block-card .wrap-content p {
  letter-spacing: -0.5px;
}

.home-2 .block-card .wrap-content .img {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 109.0666666667%;
}

.home-2 .block-card .wrap-content .img img, .home-2 .block-card .wrap-content .img iframe, .home-2 .block-card .wrap-content .img video, .home-2 .block-card .wrap-content .img canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.home-2 .block-card .wrap-content .img img {
  border-radius: 0.625rem;
}

.home-2 .home-2-slide .slide-card {
  min-height: 530px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-2 .home-2-slide .slide-card .card-content .wrap-content p {
  font-size: 14px;
  letter-spacing: -0.8px;
}

.home-2 .home-2-slide .slide-card .card-img .img {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 57.3476702509%;
}

.home-2 .home-2-slide .slide-card .card-img .img img, .home-2 .home-2-slide .slide-card .card-img .img iframe, .home-2 .home-2-slide .slide-card .card-img .img video, .home-2 .home-2-slide .slide-card .card-img .img canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.home-2 .home-2-slide .swiper-pagination {
  text-align: center;
  bottom: -1.71875rem !important;
}

@media (min-width: 1280px) {
  .home-2 .home-2-slide .swiper-pagination {
    bottom: 2.0833333333rem !important;
  }
}
.home-2 .home-2-slide .swiper-pagination span {
  width: 0.8333333333rem;
  height: 0.8333333333rem;
  border: 0.0520833333rem solid transparent;
  background: #FFFFFF;
  @apply rounded-full transition-all;
}

.home-2 .home-2-slide .swiper-pagination span.swiper-pagination-bullet-active {
  @apply bg-primary-2D853D;
}

.home-1 .block-left .wrap-content p {
  letter-spacing: -0.5px;
}

@media screen and (max-width: 640px) {
  .home-1 .block-left .wrap-content p {
    line-height: 23px;
    font-size: 16px;
  }
}
.home-1 .block-right .wrap-content .img {
  border-radius: 1.0416666667rem;
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 57.974137931%;
}

.home-1 .block-right .wrap-content .img img, .home-1 .block-right .wrap-content .img iframe, .home-1 .block-right .wrap-content .img video, .home-1 .block-right .wrap-content .img canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.home-1 .block-right .wrap-content .img img {
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.12));
  backdrop-filter: blur(9px);
}

@media screen and (max-width: 640px) {
  .home-1 .block-right .wrap-content .img {
    border-radius: 0;
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 99.4666666667%;
  }

  .home-1 .block-right .wrap-content .img img, .home-1 .block-right .wrap-content .img iframe, .home-1 .block-right .wrap-content .img video, .home-1 .block-right .wrap-content .img canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
.home-4 .home-4-slide .swiper {
  padding-bottom: 40px !important;
}

.home-4 .home-4-slide .card {
  border-radius: 20px;
  box-shadow: 1.21795px 1.21795px 6.08974px rgba(0, 0, 0, 0.07);
}

.home-4 .home-4-slide .card .thumbnail {
  border-radius: 20px 20px 0 0;
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 57%;
}

.home-4 .home-4-slide .card .thumbnail img, .home-4 .home-4-slide .card .thumbnail iframe, .home-4 .home-4-slide .card .thumbnail video, .home-4 .home-4-slide .card .thumbnail canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.home-4 .home-4-slide .card .thumbnail img {
  border-radius: 20px 20px 0 0;
}

.home-4 .home-4-slide .card .content-text {
  height: 10.4166666667rem;
}

.home-4 .home-4-slide .swiper-button-next,
.home-4 .home-4-slide .swiper-button-prev {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid rgba(51, 51, 51, 0.5);
}

.home-4 .home-4-slide .swiper-button-next::after,
.home-4 .home-4-slide .swiper-button-prev::after {
  display: none;
}

@media screen and (max-width: 1024px) {
  .home-4 .home-4-slide .swiper-button-next,
.home-4 .home-4-slide .swiper-button-prev {
    display: none;
  }
}
.home-4 .home-4-slide .swiper-button-next {
  right: -60px;
}

.home-4 .home-4-slide .swiper-button-prev {
  left: -60px;
}

.home-4 .home-4-slide .swiper-pagination {
  text-align: center;
  bottom: 0 !important;
}

.home-4 .home-4-slide .swiper-pagination span {
  width: 0.8333333333rem;
  height: 0.8333333333rem;
  border: 0.0520833333rem solid transparent;
  background: #ECECEC;
  @apply rounded-full transition-all;
}

.home-4 .home-4-slide .swiper-pagination span.swiper-pagination-bullet-active {
  @apply bg-primary-2D853D;
}