.news-icon
	@apply flex text-shadow-DEFAULT
	.title
		@apply font-normal text-xl leading-26px text-primary-051A3A
	.icon
		flex: 0 0 r(24px)
		i
			font-size: r(32px)
			line-height: r(32px)
			@apply text-secondary-224376 font-thin
	.content
		@apply ml-15px
	.info
		@apply font-normal text-sm leading-18px text-999 flex items-center text-shadow-DEFAULT
		.category
			margin-left: r(8px)
			padding-left: r(8px)
			@apply relative
			&::before
				content: ""
				width: r(1px)
				height: r(11px)
				background: #E6E6E6
				@apply absolute left-0 top-1/2 -translate-y-1/2
.news-icon-list
	.news-icon
		& + .news-icon
			padding-top: r(13px)
			margin-top: r(13px)
			border-top: r(1px) solid #E6E6E6

.bn
	@extend .zoom-img
	.title
		@apply transition-all font-bold text-2xl leading-26px
	.date
		@apply font-normal text-sm leading-18px
	&.bn-1
		.img
			a
				+img-ratio(260/450)
		.box-content
			@apply mt-5 lg:mt-6
		.date
			@apply mt-10px mb-5
		.title
			@apply text-primary-051A3A
		.ctn
			@apply font-normal text-lg leading-24px
		&:hover
			.title
				@apply text-primary-BB9C66
	&.bn-2
		@apply relative
		.img
			@apply relative
			&::before
				content: ""
				height: r(354px)
				background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)
				opacity: 0.7
				height: 60%
				z-index: 2
				@apply absolute bottom-0 left-0 w-full pointer-events-none

		.box-content
			padding: r(20px)
			@apply absolute bottom-0 left-0 w-full z-5
			@media (min-width: 1024px)
				padding: r(30px)
			.title,.date
				@apply text-white
		.date
			@apply mt-10px
		&.big
			.img
				a
					+img-ratio(590/930)
		&.small
			.img
				a
					+img-ratio(280/450)
			.box-content
				@media (max-width: 1023.98px)
					padding: 0
					@apply static mt-5
					.title,.date
						@apply text-black
		&:hover
			.title
				@apply text-primary-BB9C66
