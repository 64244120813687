footer
	.logo
		a
			width: 129px
			@media screen and ( max-width: 640px )
				width: 83px
		.back-to-top
			a
				width: 40px
				height: 40px
	#nav-footer
		li
			.menu-item-title
				p
					img
						display: none
						transition: all ease-in-out .3s
						transform: rotate(0)
				@media screen and ( max-width: 640px )
					p
						img
							display: flex
			&.active
				.menu-item-title
					p
						img
							transform: rotate(180deg)
			@media screen and ( max-width: 640px )
				.sub-menu
					display: none
	.footer-content
		ul
			li
				&::after
					content: ''
					border: 1px solid rgba($color: #222222, $alpha: .2)
					height: 20px
					position: absolute
					top: 50%
					left: -18px
					transform: translateY(-50%)
				&:first-child
					@media screen and ( max-width: 640px )
						display: none
					&::after
						display: none
