@tailwind base
@tailwind components
@tailwind utilities

@layer components
	*,
	*::before,
	*::after
		box-sizing: border-box

	*::-webkit-scrollbar-track
		@apply bg-primary-FFC20E

	*::-webkit-scrollbar
		width: r(8px)

	*::-webkit-scrollbar-thumb
		border-radius: 0
		border: r(2px) solid #999
		@apply bg-primary-2D853D

	html
		-webkit-text-size-adjust: 100%
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0)

	body
		line-height: 1.5
		font-weight: 400
		color: #111
		@apply font-body

	img
		display: inline
