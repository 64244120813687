.service
	@media screen and ( max-width: 640px )
		background: none !important
	.accordionjs
		position: static
		width: r(343px)
		.acc_section
			position: static
			@apply border-0
			.acc_head
				transition: all .3s
				border-radius: r(20px)
				@apply flex items-center justify-between
				&:hover
					@apply bg-primary-rgba
					h5,
					.material-icons-outlined
						@apply text-primary-2D853D
			.sub-nav > .accordionjs
				width: 100%
				background: #FFFFFF
				box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12)
				border-radius: 20px
				margin-bottom: 0
			#block-right_content
				width: r(530px)
				right: 0
				top: 0
		.acc_section.acc_active>.acc_head
			.material-icons-outlined
				@apply rotate-180 transition-transform
		@media screen and ( max-width: 640px )
			width: 100%
