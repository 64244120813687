.contact
	.block-left
		ul
			li
				margin-bottom: r(24px)
				div
					span
						img
							width: r(32px)
							height: r(32px)
		.infor
			ul
				li
					margin-bottom: 0
					&:last-child
						position: relative
						display: flex
						align-items: center
						&::before
							content: '\e061'
							font-family: Material Icons
							font-size: r(10px)
							margin-right: 10px
	.block-right
		.form-wrap
			border-radius: 20px
			box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15)
			h5
				padding-bottom: r(24px)
				border-bottom: 1px solid #BCC0CC
			.form-group
				width: 100%
				height: r(58px)
				input
					width: 100%
					height: 100%
			button
				&:hover
					img
						filter: brightness(0) invert(1)
			@media screen and ( max-width: 640px )
				border-radius: 0
				box-shadow: none
				padding: r(24px) 0
