.home-3
	.container--tabs
		.desktop-show
			.promo-area
				.promo-shelt
					.card
						border-radius: r(20px)
						box-shadow: r(1.21795px) r(1.21795px) r(6.08974px) rgba(0, 0, 0, 0.07)
						.thumbnail
							border-radius: r(20px) r(20px) 0 0
							+img-ratio(228/400)
							img
								border-radius: r(20px) r(20px) 0 0
						.content-text
							height: r(170px)
		.mobile-show
			.promo-area
				.home-3-slide
					.card
						box-shadow: 1.21795px 1.21795px 6.08974px rgba(0, 0, 0, 0.07)
						.thumbnail
							+img-ratio(228/400)
							background: linear-gradient(0deg, #CCCCCC, #CCCCCC)
					.swiper-wrapper
						-webkit-transition-timing-function: linear!important
						-o-transition-timing-function: linear!important
						transition-timing-function: linear!important
		.wrapper
			position: relative
			overflow: hidden
			height: calc(60/1920*100*1rem)
			.list
				position: absolute
				left: 0px
				top: 0px
				margin-top: 0px
				min-width: calc(1900/1920*100*1rem)
				li
					flex: 1 0 auto

			@media screen and ( max-width: 1024px )
				@apply overflow-x-auto h-auto
				.list
					position: static
					min-width: 100%
					padding: 0
					padding-bottom: 16px

		.scroller
			&-left
				left: 0
			&-right
				right: 0

			&-left,
			&-right
				width: calc(40/1920*100*1rem)
				height: calc(40/1920*100*1rem)
				position: absolute
				top: calc(16/1920*100*1rem)
				text-align: center
				cursor: pointer
				white-space: no-wrap
				vertical-align: middle
				display: none
				align-items: center
				justify-content: center
				border: 1px solid #222222
				background-color: #fff
				z-index: 10
				border-radius: 8px
				em
					width: 100%
					height: 100%
					display: flex
					align-items: center
					justify-content: center
		.wrapper::-webkit-scrollbar
			@media screen and ( max-width: 1024px )
				height: 4px
