.home-2
	.block-card
		border-radius: r(20px)
		.wrap-content
			p
				letter-spacing: -0.5px
			.img
				+img-ratio(409/375)
				img
					border-radius: r(12px)
	.home-2-slide
		.slide-card
			min-height: 530px
			padding: 24px
			display: flex
			flex-direction: column
			justify-content: space-between
			.card-content
				.wrap-content
					p
						font-size: 14px
						letter-spacing: -0.8px
			.card-img
				.img
					+img-ratio(160/279)
		.swiper-pagination
			text-align: center
			bottom: r(-33px) !important
			@media (min-width: 1280px)
				bottom: r(40px) !important
			span
				width: r(16px)
				height: r(16px)
				border: r(1px) solid transparent
				background: #FFFFFF
				@apply rounded-full transition-all
				&.swiper-pagination-bullet-active
					@apply bg-primary-2D853D
