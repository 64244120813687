.home-1
	.block-left
		.wrap-content
			p
				letter-spacing: -0.5px
				@media screen and ( max-width: 640px )
					line-height: 23px
					font-size: 16px
	.block-right
		.wrap-content
			.img
				border-radius: r(20px)
				+img-ratio(269/464)
				img
					filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.12))
					backdrop-filter: blur(9px)
				@media screen and ( max-width: 640px )
					border-radius: 0
					+img-ratio(373/375)
