.project,
.news
	.card
		background: rgba(255, 255, 255, 0.94)
		box-shadow: 1.21795px 1.21795px 6.08974px rgba(0, 0, 0, 0.07)
		border-radius: 20px
		.card-img
			border-radius: 20px 20px 0 0
			+img-ratio(228/400)
	.sigle-slide
		.card
			border-radius: 20px
			background: #FFFFFF
			box-shadow: 1.21795px 1.21795px 12px rgba(0, 0, 0, 0.2)
			.thumbnail
				+img-ratio(196/343)
.news-detail
	.article
		.other-article
			.card
				background: rgba(255, 255, 255, 0.94)
				border-radius: 20px
				box-shadow: 1.21795px 1.21795px 6.08974px rgba(0, 0, 0, 0.07)
				.card-img
					border-radius: 20px 20px 0 0
					+img-ratio(228/400)
