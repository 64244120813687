#main-banner
	.news-banner
		.box-image
			.img
				&::before
					position: absolute
					top: 0
					left: 0
					width: 100%
					height: 100%
					content: ''
					background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 62.39%),
					z-index: 999
				@media screen and ( max-width: 640px )
					a
						+img-ratio(210/375)
		.wrap-content-list
			left: r(80px)
			max-width: r(844px)
			width: 100%
			z-index: 1000
		.swiper-pagination
			left: r(80px)
.news
	.search-wrap
		max-width: r(800px)
		width: 100%
		height: r(58px)
		button
			position: absolute
			top: r(15px)
			right: r(20px)
	.box-item
		.thumbnail
			min-width: 170px
			height: 98px
			img
				width: 100%
				height: 100%
				object-fit: cover
			@media screen and ( max-width: 640px )
				min-width: 112px
				height: 64px
		.box-centent
			@media screen and ( max-width: 640px )
				.desc
					display: none
	@media screen and ( max-width: 640px )
		.box-item
			border-bottom: 1px dashed #DDE1EB
			&:last-child
				border: 0
