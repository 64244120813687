.project-detail
	.wrap-container
		.button-link
			a
				width: 44px
				height: 44px
				border: 1px solid #333333
				border-radius: 50%
		.wrap-desc
			@media screen and ( max-width: 640px )
				p
					letter-spacing: 0.01em
					line-height: 26px
					font-size: 18px
					padding: 0
	.container--tabs
		.nav-tabs
			border-radius: 12px
			li
				border: 1.4px solid #DDE1EB
				height: 100%
				a
					padding: r(14px) r(30px)
					height: 100%
				&:first-child
					border-radius: 12px 0 0 12px
					border-right: 1.4px solid transparent
					a
						border-radius: 12px 0 0 12px
				&:last-child
					border-radius: 0 12px 12px 0
					a
						border-radius: 0 12px 12px 0
				&:nth-child(2)
					border-right: 1.4px solid transparent
				&:nth-child(3)
					border-right: 1.4px solid transparent
				&.active
					a
						@apply font-bold text-primary-FFFFFF bg-primary-2D853D border-0
		.tab-content
			.panel
				box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12)
				&-header
					padding-bottom: 10px
					&::before
						content: ''
						position: absolute
						bottom: 0
						left: 0
						width: r(300px)
						height: 1px
						background: #222222
				.card
					.thumbnail
						+img-ratio(228/400)
